@font-face {
  font-family: "Gopher-Regular";
  src: url("./fonts/Gopher/Gopher-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "Gopher-Regular";
}

:root {
  --background_primary: #1d1b29;
  --background_secondary: linear-gradient(109.6deg, rgba(17, 17, 17, 0.93) 11.2%, rgb(26, 25, 25) 78.9%);
  --fontColor_primary: #fff;
  --shadow_primary: rgba(136, 136, 136, 0.3) 0px 2px 20px;
}

.App {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: var(--background_primary);
  color: var(--fontColor_primary);
}

section {
  overflow: hidden;
  background: var(--background_primary);
}

.bg-black {
  background-color: var(--background_primary) !important;
}

.textColorPrimary {
  color: #3369F1;
}

.textColorSecondary {
  color: #8100C9 !important;
}

.buttonPrimary {
  border: 0px solid rgb(71, 67, 197);
  background: 0% 0%/auto auto linear-gradient(126deg, #3662f1 24%, #b71cf6 100%);
  border-radius: 10px;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
  color: #fff;
  font-weight: 600;
}

.buttonPrimary:hover {
  background: 0% 0%/auto auto linear-gradient(126deg, #b71cf6 24%, #3662f1 100%);
}

.buttonOutline {
  background-color: #FFFCF8;
  color: #5F5BCD;
  border: 0px solid #5f5bcd;
  border-radius: 50px;
  padding: 8px 20px;
  box-shadow: 0 0 6px #5f5bcdb3;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}

.buttonOutline:hover {
  box-shadow: 0 0 12px #5f5bcdb3;
  cursor: default;
}

.buttonGradiant {
  border: 0px solid #4743c5;
  background: 0% 0%/auto auto linear-gradient(126deg, #3662f1 24%, #b71cf6 100%);
  border-radius: 10px;
  padding: 8px 20px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}

.buttonGradiant:hover {
  background: 0% 0%/auto auto linear-gradient(126deg, #b71cf6 24%, #3662f1 100%);
}

.form-control, select, textarea, .dropdown-toggle, .dropdown-menu {
  background: var(--background_primary) !important;
  color: #fff !important;
  border: none !important;
  box-shadow: var(--shadow_primary) !important;
  border-radius: 30px !important;
}

.dropdown-menu {
  min-height: 200px !important;
  max-height: 350px !important;
  overflow: auto;
  border-radius: 10px !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding: 5px !important;
}

.dropdown-menu .actions-btn {
  background: var(--background_primary) !important;
  color: #fff !important;
  outline: none !important;
  border: none !important;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-item {
  color: #fff !important;
}

.form-check-label {
  cursor: pointer !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #5f5bcd !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.form-label {
  font-size: 15px !important;
}

input::placeholder {
  color: #fff !important;
  font-size: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.progress-bar-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: 0% 0%/auto auto linear-gradient(126deg, #b71cf6 24%, #3662f1 100%);
  transform-origin: 0%;
  z-index: 999;
  border-radius: 10px;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: 0% 0%/auto auto linear-gradient(126deg, #b71cf6 24%, #3662f1 100%);
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 35px;
  width: 35px;
}

.scroll-to-top-button img {
  filter: brightness(5);
}

.scroll-to-top-button.visible {
  opacity: 1;
}

.swal-modal, .modal-content {
  background: var(--background_primary) !important;
  color: #fff !important;
  box-shadow: var(--shadow_primary);
  border-radius: 8px;
}

.swal-title, .swal-text{
  color: #fff !important;
}

.swal-icon--success:before,
.swal-icon--success:after,
.swal-icon--success__hide-corners {
  background: var(--background_primary) !important;
}

@media only screen and (max-width:500px) {
  .form-label {
    font-size: 14px !important;
  }
}