.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 80px;
    z-index: 100;
    transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.scrolled {
    box-shadow: var(--shadow_primary);
}

.navbarLogo img {
    width: 280px;
    height: 53px;
    object-fit: contain;
}

.navbarMenu {
    display: flex;
}

.navbarMenu a {
    margin-left: 25px;
    text-decoration: none;
    color: var(--fontColor_primary);
    transition: color 0.3s;
    font-weight: 700;
}

.navbarMenu a:hover {
    color: #5f5bcdb3;
}

.navbarToggle {
    display: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        padding: 5px;
    }

    .navbarMenu {
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-bottom: 10px;
        background-color: var(--background_primary);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;
        justify-content: center;
        align-items: center;
    }

    .menuOpen{
        background: var(--background_primary);
    }

    .navbarMenu a {
        margin: 10px 0;
    }

    .navbarMenu.open {
        opacity: 1;
        pointer-events: auto;
    }

    .navbarToggle {
        display: block;
        padding-right: 15px;
    }
}