.footer {
    overflow: hidden;
    padding: 0px 100px 50px 100px;
    background: var(--background_primary);
}

.footer .border {
    border-top: 1px solid;
    padding-top: 50px;
}

.footer img {
    width: 280px;
    height: 53px;
    object-fit: contain;
}

.footerTop {
    border-bottom: 1px solid;
    padding-bottom: 40px;
}

.footerTop .links {
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
}

.footerTop .links a {
    text-decoration: none;
    color: var(--fontColor_primary);
    font-size: 18px;
    display: inline;
}

.footerTop .links a:hover {
    text-decoration: underline;
}

.contact {
    margin-bottom: 20px;
}

.contact p {
    margin: 0;
}

.contact p a {
    text-decoration: none;
    color: var(--fontColor_primary);
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.address p, .text p {
    max-width: 220px;
    font-size: 18px;
    color: var(--fontColor_primary);
}

.footerBottom {
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerBottom p {
    font-size: 18px;
    color: var(--fontColor_primary);
}

.footerBottom .social {
    display: flex;
    gap: 20px;
    font-size: 30px;
}

.footerBottom .social a {
    color: var(--fontColor_primary);
}

@media only screen and (max-width: 768px) {
    .footer {
        padding: 20px;
    }

    .footerTop {
        text-align: center;
    }

    .footerTop .links {
        margin: 20px 0;
    }

    .address p, .text p {
        max-width: 100%;
    }

    .footerBottom {
        flex-wrap: wrap;
        justify-content: center;
    }

    .footerBottom .social {
        display: flex;
        gap: 30px;
        font-size: 25px;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .footerBottom p {
        font-size: 16px;
        color: var(--fontColor_primary);
        text-align: center;
    }
}