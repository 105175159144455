.section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 50px;
    background: transparent;
}

.section1 .topSection {
    max-width: 1000px;
}

.section1 .preHeading {
    font-size: 30px;
    font-family: sriracha, cursive;
    margin-bottom: 5px;
}

.section1 .heading {
    font-size: 81px;
    font-weight: 700;
    line-height: 1.1;
}

.section1 .heading .wordAnimationWrapper {
    display: inline-flex;
}

.section1 .topSectionChild {
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 20px;
}

.section1 .topSectionChild button {
    height: 50px;
}

.section1 .subHeading {
    font-size: 18px;
    line-height: 1.7em;
    text-wrap: balance;
}


/* ----------------------------Section 2------------------------------- */
.section2 {
    background: var(--background_primary);
    min-height: 350px;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    overflow: hidden;
}

.section2 .h3 {
    font-size: 40px;
    font-weight: 700;
}

.section2 .boxes {
    max-width: 1000px;
    gap: 20px 0;
    justify-content: center;
}

.section2 .boxes .box {
    background: var(--background_primary);
    padding: 30px;
    border-radius: 10px;
    max-width: 320px;
    box-shadow: var(--shadow_primary);
}

.section2 .boxes .box p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}



/* ----------------------------Section 3------------------------------- */
.section3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    background: var(--background_primary);
}

.section3 .content {
    max-width: 1000px;
}

.section3 .content .h2 {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 20px;
}

.section3 .content .button {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.section3 .content .button img {
    position: relative;
    bottom: -33px;
}

.section3 .content h6 {
    font-size: 30px;
    font-weight: 700;
}

.section3 .content .p {
    font-size: 18px;
}

.section3 .mentors {
    margin-top: 50px;
    display: grid;
    place-items: center;
}

.section3 .mentors .mentorsImg {
    width: 100%;
}

.section3 .mentors .mentorsImg img {
    border-radius: 10px;
}

.section3 .mentors .worldsImage {
    position: relative;
    bottom: 30px;
}


.section3 .mentors .mentorsContent {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    position: relative;
}

.section3 .mentors .mentorsContent .card {
    width: 200px;
    border-radius: 10px;
    box-shadow: var(--shadow_primary);
    background-color: var(--background_primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 0 20px;
    z-index: 10;
    gap: 10px;
}

.section3 .mentors .mentorsContent .card h5 {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    line-height: 35px;
}

.section3 .mentors .mentorsContent .card img {
    max-width: 100%;
}

.section3 .mentors .mentorsContent .line1 {
    width: 1px;
    position: absolute;
    color: #ffffff;
    top: 0;
    height: 85px;
    background: #ffffff;
    left: 20%;
    top: -82px;
    z-index: 1;
}

.section3 .mentors .mentorsContent .line2 {
    width: 1px;
    position: absolute;
    color: #ffffff;
    top: 0;
    height: 85px;
    background: #ffffff;
    top: -82px;
    z-index: 1;
}

.section3 .mentors .mentorsContent .line3 {
    width: 1px;
    position: absolute;
    color: #ffffff;
    top: 0;
    height: 85px;
    background: #ffffff;
    right: 20%;
    top: -82px;
    z-index: 1;
}

.section3 .mentors .mentorsContentMob {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
}


.section3 .mentors .mentorsContentMob .mentorsContentMobTop {
    display: flex;
    justify-content: space-between;
    position: relative;
}


.section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom .card,
.section3 .mentors .mentorsContentMob .mentorsContentMobTop .card {
    width: 160px;
    border-radius: 10px;
    box-shadow: var(--shadow_primary);
    background-color: var(--background_primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    padding: 0 20px;
    z-index: 10;
}

.section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom .card h5,
.section3 .mentors .mentorsContentMob .mentorsContentMobTop .card h5 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    line-height: 30px;
}

.section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom .card img,
.section3 .mentors .mentorsContentMob .mentorsContentMobTop .card img {
    max-width: 100%;
}

.section3 .mentors button {
    margin-top: 50px;
}



/* ----------------------------Section 4------------------------------- */

.section4 {
    background: transparent;
    padding: 30px 0;
}

.section4 .headings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section4 .headings .h2 {
    font-size: 46px;
    font-weight: 700;
    max-width: 1000px;
    margin-bottom: 80px;
}

.section4 .content,
.section4 .content2,
.section4 .content3 {
    height: 610px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.section4 .content2 {
    flex-direction: row-reverse;
}

.section4 .content .boxLeft,
.section4 .content2 .boxLeft,
.section4 .content3 .boxLeft {
    background-color: #2da4f3;
    height: 610px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 40%;
}

.section4 .content2 .boxLeft {
    background-color: #fb93ff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.section4 .content3 .boxLeft {
    background-color: #a9f070;
}

.section4 .content .boxLeft img,
.section4 .content2 .boxLeft img,
.section4 .content3 .boxLeft img {
    position: relative;
    left: 50%;
    width: 100%;
    border-radius: 10px;
}

.section4 .content2 .boxLeft img {
    left: -70%;
}

.section4 .content .boxRight,
.section4 .content2 .boxRight,
.section4 .content3 .boxRight {
    max-width: 500px;
    margin-right: 80px;

}

.section4 .content2 .boxRight {
    margin-right: 0;
    margin-left: 160px;
}

.section4 .content3 .boxRight {
    margin-right: 0;
    margin-right: 100px;
}

.section4 .content .boxRight h3,
.section4 .content2 .boxRight h3,
.section4 .content3 .boxRight h3 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 25px;
}

.section4 .content .boxRight h5,
.section4 .content2 .boxRight h5,
.section4 .content3 .boxRight h5 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 25px;
}

.section4 .content .boxRight p,
.section4 .content2 .boxRight p,
.section4 .content3 .boxRight p {
    font-size: 17px;
    position: relative;
    top: 6px;
}

.section4 .content .boxRight .points .point,
.section4 .content2 .boxRight .points .point,
.section4 .content3 .boxRight .points .point {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}


/* ----------------------------Section 5------------------------------- */
.section5 {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background_primary);
}

.section5 .headings {
    max-width: 1000px;
    text-align: center;
}

.section5 .headings .h2 {
    font-size: 46px;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 20px;
}

.section5 .headings .p {
    font-size: 18px;
    line-height: 1.6em;
    margin-bottom: 20px;
}

.section5 .headings .points {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.section5 .headings .points .point {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}



/* ----------------------------Section 6------------------------------- */
.section6 {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.section6 .top {
    background-color: var(--background_primary);
    max-width: 95%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 50px;
    position: relative;
    bottom: 33px;
    box-shadow: var(--shadow_primary);
}

.section6 .top .topLeft {
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.section6 .top .topLeft {
    padding-left: 10%;
}

.section6 .top .topLeft h2 {
    font-size: 30px;
    line-height: 10px;
}

.section6 .top .topLeft p {
    font-size: 50px;
    font-weight: 700;
}

.section6 .top .topRight {
    padding: 40px 60px;
    box-shadow: var(--shadow_primary);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.section6 .top .topRight p:first-child {
    font-size: 25px;
    max-width: 500px;
}

.section6 .top .topRight p:nth-child(2) {
    font-size: 15px;
    max-width: 500px;
}

.section6 .top .topRight .coFounder {
    display: flex;
    gap: 20px;
}

.section6 .top .topRight .coFounder img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.section6 .top .topRight .coFounder .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section6 .top .topRight .coFounder .content p {
    font-size: 16px;
    margin-bottom: 0;
}

.section6 .bottom {
    text-align: center;
    padding-bottom: 50px;
}

.section6 .bottom .content {
    max-width: 1000px;
    text-align: center;
}

.section6 .bottom .content .h2 {
    font-size: 46px;
}

.section6 .bottom .content form {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.section6 .bottom .content button {
    height: 46px;
    position: relative;
    top: 16px;
    right: 21px;
}

@media only screen and (max-width:1320px) {

    .section4 .content .boxLeft,
    .section4 .content2 .boxLeft,
    .section4 .content3 .boxLeft {
        background-color: #2da4f3;
        height: 610px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 35%;
    }

    .section4 .content2 .boxLeft {
        background-color: #fb93ff;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .section4 .content3 .boxLeft {
        background-color: #a9f070;
    }

    .section4 .content .boxLeft img {
        position: relative;
        left: 30%;
        width: 100%;
        border-radius: 10px;
    }

    .section4 .content2 .boxLeft img {
        left: -50%;
    }

    .section4 .content .boxRight,
    .section4 .content2 .boxRight,
    .section4 .content3 .boxRight {
        margin-right: 50px;

    }

    .section4 .content2 .boxRight {
        margin-right: 0;
        margin-left: 80px;
    }

    .section4 .content3 .boxRight {
        margin-right: 80px;
    }
}

@media only screen and (max-width:1000px) {
    .section4 .content,
    .section4 .content2,
    .section4 .content3 {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 150px;
        flex-direction: column;
        gap: 50px;
    }

    .section4 .content .boxLeft,
    .section4 .content2 .boxLeft,
    .section4 .content3 .boxLeft {
        background-color: #2da4f3;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        width: 100%;
    }

    .section4 .content2 .boxLeft {
        background-color: #fb93ff;
    }

    .section4 .content3 .boxLeft {
        background-color: #a9f070;
    }

    .section4 .content .boxLeft img {
        position: relative;
        left: 0;
        width: 100%;
        border-radius: 10px;
        bottom: 120px;
    }

    .section4 .content2 .boxLeft img {
        left: 0;
        bottom: 120px;
    }

    .section4 .content3 .boxLeft img {
        left: 0;
        bottom: 120px;
    }

    .section4 .content .boxRight,
    .section4 .content2 .boxRight,
    .section4 .content3 .boxRight {
        margin: 0;

    }

    .section4 .content2 .boxRight {
        margin: 0;
    }

    .section4 .content3 .boxRight {
        margin: 0;
    }
}



@media only screen and (max-width:768px) {
    .section1 {
        padding: 100px 10px;
    }

    .section2, .section3 {
        padding: 50px 10px;
        padding-bottom: 100px;
    }

    .section1 .preHeading {
        font-size: 24px;
    }

    .section1 .heading {
        font-size: 40px;
    }

    .section1 .topSectionChild button {
        height: 50px;
        margin-top: 20px;
    }

    .section2 h3 {
        font-size: 35px;
    }


    .section2 .boxes .box h5 {
        font-size: 35px;
    }

    .section2 .boxes .box p {
        font-size: 15px;
    }


    .section3 .content .h2 {
        font-size: 30px;
    }

    .section3 .content .button img {
        position: relative;
        bottom: -33px;
    }

    .section3 .content h6 {
        font-size: 25px;
        font-weight: 700;
    }

    .section3 .content .p {
        font-size: 15px;
    }

    .section3 .mentors .mentorsContent .card {
        width: 140px;
    }

    .section3 .mentors .mentorsContent .line1,
    .section3 .mentors .mentorsContent .line2,
    .section3 .mentors .mentorsContent .line3 {
        top: -70px;
    }

    .section3 .mentors .mentorsContentMob {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
    }


    .section4 .headings .h2 {
        margin-bottom: 0;
    }

    .section4 .boxRight {
        height: 300px;
    }

    .section4 .headings .h2 {
        font-size: 35px;
        padding: 0 10px;
    }

    .section4 .content2{
        margin-top: 200px;
    }

    .section4 .content .boxRight,
    .section4 .content2 .boxRight,
    .section4 .content3 .boxRight {
        padding: 0 15px;
    }

    .section4 .content .boxRight h3,
    .section4 .content2 .boxRight h3,
    .section4 .content3 .boxRight h3 {
        font-size: 30px;
    }

    .section4 .content .boxRight h5,
    .section4 .content2 .boxRight h5,
    .section4 .content3 .boxRight h5 {
        font-size: 20px;
    }

    .section4 .content .boxRight p,
    .section4 .content2 .boxRight p,
    .section4 .content3 .boxRight p {
        font-size: 16px;
    }

    .section5 .headings {
        padding: 0 10px;
    }

    .section5 .headings .h2 {
        font-size: 35px;
    }

    .section5 .headings .p {
        font-size: 16px;
    }

    .section5 .headings .points {
        gap: 10px;
    }

    .section6 {
        padding: 50px 0 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section6 .top {
        max-width: 95%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        padding: 50px 5px;
        position: relative;
        bottom: 33px;
    }

    .section6 .top .topLeft {
        height: 100%;
    }

    .section6 .top .topLeft {
        padding-left: 1%;
    }

    .section6 .top .topLeft h2 {
        font-size: 20px;
    }

    .section6 .top .topLeft p {
        font-size: 30px;
    }

    .section6 .top .topRight {
        padding: 30px 20px;
        gap: 15px;
    }

    .section6 .top .topRight p:first-child {
        font-size: 20px;
    }

    .section6 .top .topRight .coFounder {
        display: flex;
        gap: 10px;
    }

    .section6 .top .topRight .coFounder img {
        width: 50px;
        height: 50px;
    }

    .section6 .top .topRight .coFounder .content p {
        font-size: 12px;
        margin-bottom: 0;
    }

    .section6 .bottom .content .h2 {
        font-size: 30px;
    }

    .section6 .bottom .content form {
        margin-top: 30px;
    }

    .section6 .bottom .content button {
        width: 200px;
        position: static;
        margin-top: 20px;
    }


}

@media only screen and (max-width:450px) {
    .section1 .heading {
        font-size: 32px;
    }

    .section1 .subHeading {
        font-size: 16px;
    }

    .section3 .content h6 {
        font-size: 25px;
    }

    .section3 .content .p {
        font-size: 16px;
    }

    .section3 .mentors button {
        position: relative;
        top: 50px;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom .card,
    .section3 .mentors .mentorsContentMob .mentorsContentMobTop .card {
        width: 140px;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTop .line1 {
        width: 1px;
        position: absolute;
        color: #000;
        top: 0;
        height: 48px;
        background: #fff;
        left: 18%;
        top: -48px;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTop .line2 {
        width: 1px;
        position: absolute;
        color: #000;
        top: 0;
        height: 48px;
        background: #fff;
        right: 18%;
        top: -48px;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom {
        position: relative;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTopBottom .line {
        width: 1px;
        position: absolute;
        color: #000;
        top: 0;
        height: 313px;
        background: #fff;
        left: 50%;
        top: -313px;
    }

    .section4 .headings .h2 {
        font-size: 32px;
    }

    .section5 .headings .h2 {
        font-size: 26px;
    }

    .section6 .bottom .content .h2 {
        font-size: 26px;
    }
}

@media only screen and (max-width:400px) {

    .section3 .mentors .mentorsContentMob .mentorsContentMobTop .line1 {
        left: 23%;
    }

    .section3 .mentors .mentorsContentMob .mentorsContentMobTop .line2 {
        right: 23%;
    }
}