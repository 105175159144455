.about {
    padding-top: 120px;
    overflow: hidden;
}

.about .headings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about .heading{
    font-size: 50px;
    font-weight: 700;
    max-width: 900px;
    text-align: center;
}

.about .content,
.about .content2 {
    height: 610px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.about .content2 {
    flex-direction: row-reverse;
}

.about .content .boxLeft,
.about .content2 .boxLeft {
    background-color: #2da4f3;
    height: 610px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 30%;
}

.about .content2 .boxLeft {
    background-color: #fb93ff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}


.about .content .boxLeft img,
.about .content2 .boxLeft img {
    position: relative;
    left: 50%;
    width: 70%;
    border-radius: 10px;
}

.about .content2 .boxLeft img {
    left: -40%;
}

.about .content .boxRight,
.about .content2 .boxRight {
    max-width: 500px;
    margin-right: 160px;

}

.about .content2 .boxRight {
    margin-right: 0;
    margin-left: 160px;
}


.about .content .boxRight h3,
.about .content2 .boxRight h3 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 25px;
}


.about .content .boxRight p,
.about .content2 .boxRight p {
    font-size: 17px;
    position: relative;
    top: 6px;
}

.contactForm{
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: var(--background_primary);
    padding: 60px 0;
}

.contactForm h6{
    font-size: 50px;
    font-weight: 700;
}

.contactForm p{
    font-size: 30px;
    max-width: 646px;
    margin-top: 10px;
}

.formContainer{
    width: 100%;
    margin-top: 50px;
    display: grid;
    place-items: center;
}

.formContainer form {
    width: 100%;
    max-width: 900px;
}

@media only screen and (max-width:1320px) {
    .about .content .boxLeft,
    .about .content2 .boxLeft {
        background-color: #2da4f3;
        height: 610px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 35%;
    }

    .about .content2 .boxLeft {
        background-color: #fb93ff;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }


    .about .content .boxLeft img {
        position: relative;
        left: 30%;
        width: 100%;
        border-radius: 10px;
    }

    .about .content2 .boxLeft img {
        left: -50%;
    }

    .about .content .boxRight,
    .about .content2 .boxRight {
        margin-right: 30px;

    }

    .about .content2 .boxRight {
        margin-right: 0;
        margin-left: 30px;
    }
}

@media only screen and (max-width:1000px) {
    .about .content,
    .about .content2 {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 150px;
        flex-direction: column;
        gap: 50px;
    }

    .about .content .boxLeft,
    .about .content2 .boxLeft {
        background-color: #2da4f3;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        width: 100%;
        text-align: center;
    }

    .about .content2 .boxLeft {
        background-color: #fb93ff;
    }

    .about .content .boxLeft img {
        position: relative;
        left: 0;
        width: 50%;
        border-radius: 10px;
        bottom: 120px;
    }

    .about .content2 .boxLeft img {
        left: 0;
        bottom: 120px;
    }

    .about .content .boxRight,
    .about .content2 .boxRight {
        margin: 0;

    }

    .about .content2 .boxRight {
        margin: 0;
    }

}

@media only screen and (max-width:768px) {

    .about .headings {
        padding:0 10px;
    }
    
    .about .heading{
        font-size: 30px;
    }

    .about .content .boxRight,
    .about .content2 .boxRight {
        padding: 0 15px;
    }

    .about .content .boxRight h3,
    .about .content2 .boxRight h3 {
        font-size: 30px;
    }

    .about .content .boxRight h5,
    .about .content2 .boxRight h5 {
        font-size: 20px;
    }

    .about .content .boxRight p,
    .about .content2 .boxRight p {
        font-size: 16px;
    }

    .contactForm h6{
        font-size: 35px;
    }
    
    .contactForm p{
        font-size: 20px;
    }
    
    .formContainer{
        padding: 0 10px;
    }

}