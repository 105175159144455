.modal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header {
    display: flex;
    justify-content: center;
}

.header h6 {
    font-size: 20px;
    font-weight: 700;
}

.header button {
    width: 20px;
    background: transparent;
    border: none;
    border-radius: 50%;
    outline: none;
    position: absolute;
    right: 20px;
}

.header button img {
    filter: invert(1);
    border-radius: 50%;
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.footer button {
    height: 35px;
    display: flex;
    align-items: center;
}