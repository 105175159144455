.educators {
    overflow: hidden;
}

.section1 {
    padding: 120px;
    overflow: hidden;
    background: transparent;
}

.section1Left {
    padding-top: 50px;
}

.section1Left .h2 {
    font-size: 36px;
    font-weight: 700;
    max-width: 500px;
    margin-bottom: 20px;
}

.section1Left .p {
    font-size: 20px;
    max-width: 500px;
}

.section1LeftBtn {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-top: 50px;
}

.section1LeftBtn img {
    width: 164px;
    position: relative;
    top: 5px;
}

.section1LeftBtn button {
    width: 200px;
}

.section1Right img {
    width: 100%;
}

.section2 {
    background: var(--background_primary);
}

.section2Top {
    height: 550px;
}

.section2TopLeft {
    width: fit-content;
}

.section2TopLeft img {
    height: 550px;
    width: 500px;
}

.section2TopRight {
    max-width: 650px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    right: 10%;
}

.section2TopRight h6 {
    font-size: 23px;
    font-weight: 600;
}

.section2TopRight h2 {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;
}

.section2TopRight .line {
    width: 80px;
    height: 2px;
    background-color: #000000;
    margin: 0;
    margin-bottom: 30px;
}

.section2TopRight p {
    font-size: 18px;
    margin-bottom: 30px;
}

.section2TopRight button {
    display: inline;
    width: 200px;
}

.section2Bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.section2Bottom .section2BottomWrapper {
    display: flex;
    justify-content: space-between;

}

.section2Bottom .section2BottomWrapper .animated .animationContainer,
.section2Bottom .animated .animationContainer {
    width: 100%;
    overflow: hidden;
}

.section2Bottom .section2BottomWrapper .animated .animationContainer .animationWrapper,
.section2Bottom .animated .animationContainer .animationWrapper {
    display: flex;
    animation-delay: 0.2s;
    margin-bottom: 30px;
    white-space: nowrap;
    margin-top: 10px;
}

.section2Bottom .section2BottomWrapper .animated .animationContainer .animationWrapper:first-child,
.section2Bottom .animated .animationContainer .animationWrapper:first-child {
    animation: slide 15s linear infinite;
}

.section2Bottom .section2BottomWrapper .animated .animationContainer .animationWrapper:nth-child(2),
.section2Bottom .animated .animationContainer .animationWrapper:nth-child(2) {
    animation: slide 14s linear infinite;
}

.section2Bottom .section2BottomWrapper .animated .animationContainer .animationWrapper:nth-child(3),
.section2Bottom .animated .animationContainer .animationWrapper:nth-child(3) {
    animation: slide 16s linear infinite;
}

.section2Bottom .section2BottomWrapper .animated .animationContainer .animationWrapper .name,
.section2Bottom .animated .animationContainer .animationWrapper .name {
    font-size: 15px;
    margin: 0 5px;
    background-color: var(--background_primary);
    color: #5F5BCD;
    border: 0px solid #5f5bcd;
    border-radius: 50px;
    padding: 8px 20px;
    box-shadow: 0 0 6px #5f5bcdb3;
    font-weight: bold;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}


.section2Bottom .section2BottomWrapper .animated .btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #5F5BCD;
}

.section2Bottom .section2BottomWrapper .content {
    padding-left: 10px;
}

.section2Bottom .section2BottomWrapper .content h6 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: left !important;
}

.section2Bottom .section2BottomWrapper .content p {
    font-size: 18px;
    margin-bottom: 30px;
    text-align: left !important;
}

.section3 {
    background: var(--background_primary);
    padding: 100px 0;
}

.section3 .top {
    padding: 0 50px;
}

.section3 .top .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.section3 .top .left h6 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
}

.section3 .top .left .point {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.section3 .top .left .point p {
    font-size: 18px;
}

.section3 .top .right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.section3 .top .right img {
    width: 90%;
    border-radius: 50%;
    border: 4px solid yellow;
}

.section3 .bottom {
    margin-top: 50px;
    display: none;
}

.section3 .bottom h6 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
}


.section3 .bottom .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
}

.section3 .bottom .wrapper .card {
    position: relative;
}

.section3 .bottom .wrapper .card .caption {
    position: absolute;
    bottom: 0;
    left: 15px;
    padding: 5px;
    text-align: center;
    background-color: #ffffff;
    opacity: 0.7;
    width: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.section3 .bottom .wrapper .card .caption p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
}

.section3 .bottom .wrapper .card img {
    border-radius: 20px;
}

.section3 .features {
    margin-top: 100px;
}

.section3 .features .feature {
    margin-bottom: 50px;
}

.section3 .features .feature .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section3 .features .feature .content h6 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
}

.section3 .features .feature .content p {
    font-size: 17px;
}

.section3 .features .feature .img img {
    border-radius: 10px;
    width: 100%;
}


.section4 {
    padding: 60px 0;
    background: transparent;
}

.section4 .top {
    width: 100%;
    display: grid;
    place-items: center;
}

.section4 .top * {
    max-width: 900px;
}

.section4 .top .h4 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}

.section4 .top .p {
    font-size: 25px;
    text-align: center;
    max-width: 600px;
    margin-bottom: 20px;
}

.section4 .top img {
    margin-bottom: 30px;
}

.section4 .top button {
    width: 200px;
}

.section4 .mid {
    padding-top: 50px;
}

.section4 .mid {
    padding-top: 100px;
}

.section4 .mid h4 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 100px;
}

.section4 .mid .snapScollContainer {
    overflow: scroll;
    height: 85vh;
    width: 100%;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.section4 .mid .snapScollContainer::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.section4 .mid .wrapper .h5 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
}

.section4 .mid .wrapper {
    margin-bottom: 100px;
    scroll-snap-align: center;
    display: flex;
    justify-content: space-between;
    height: 80vh;
    align-items: center;
}

.section4 .mid .wrapper .grid {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 30px;
    place-items: center;
    width: 80vw;
    height: 500px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 10px;
    padding-left: 100px;
    overflow: scroll;
    height: 500px;
    width: 100%;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.section4 .mid .wrapper .grid::-webkit-scrollbar {
    display: none;
}

.section4 .mid .wrapper .grid .card {
    margin-bottom: 50px;
    min-height: 450px;
    text-align: center;
    padding: 30px 20px;
    max-width: 350px;
    box-shadow: var(--shadow_primary);
    border-radius: 20px;
    background: var(--background_primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-snap-align: center;
}

.section4 .mid .wrapper .grid .card img {
    margin-bottom: 20px;
}

.section4 .mid .wrapper .grid .card h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.section4 .mid .wrapper .grid .card p {
    font-size: 16px;
}

.section4 .bottom {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.section4 .bottom .imgBox {
    max-width: 1000px;
    position: relative;

}

.section4 .bottom .imgBox img {
    border-radius: 20px;
    box-shadow: var(--shadow_primary);
}

.section4 .bottom .imgBox .content {
    position: absolute;
    max-width: 450px;
    top: 25%;
    left: 5%;
}

.section4 .bottom .imgBox .content h6 {
    font-size: 35px;
    font-weight: 700;
}

.section4 .bottom .imgBox .content p {
    font-size: 17px;
    margin-bottom: 30px;
}

.contactSection {
    padding: 50px 0;
    background: transparent;
}

.formText h3 {
    font-weight: 700;
}

.formContainer {
    display: block;
    width: 100%;
    margin: auto;
    max-width: 1100px;
    padding: 50px;
    border-radius: 20px;
}

@media only screen and (max-width:1200px) {
    .section4 .mid .snapScollContainer {
        overflow: scroll;
        height: 100%;
        width: 100%;
        scroll-snap-type: y mandatory;
    }
}

@media only screen and (max-width:1100px) {
    .section2TopLeft img {
        width: 400px;
    }

    .section2TopRight {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        right: 0%;
    }
}

@media only screen and (max-width:900px) {
    .section1 {
        padding: 120px 50px;
    }

    .section3 .top {
        padding: 0 20px;
    }

    .bottom .imgBox .content {
        top: 25%;
        left: 2%;
    }

    .section4 .bottom .imgBox .content h6 {
        font-size: 30px;
    }

    .section4 .bottom .imgBox .content p {
        font-size: 17px;
    }
}

@media only screen and (max-width:768px) {
    .section1 {
        padding: 80px 15px;
    }

    .section1LeftBtn {
        align-items: center;
        margin-bottom: 50px;
    }

    .section1Left .h2 {
        font-size: 28px;
    }

    .section1Left .p {
        font-size: 16px;
    }

    .section2Top {
        height: auto;
    }

    .section2TopLeft {
        width: 100%;
    }

    .section2TopLeft img {
        width: 100%;
        height: 100%;
    }


    .section2TopRight {
        position: static;
        padding: 30px 20px;
        margin-bottom: 50px;
    }

    .section2TopRight h6 {
        font-size: 18px;
    }

    .section2TopRight h2 {
        font-size: 26px;
    }

    .section2TopRight p {
        font-size: 16px;
    }

    .section2Bottom {
        margin-top: 20px;
    }

    .section2Bottom .section2BottomWrapper .content {
        padding-left: 0;
    }

    .section2Bottom .section2BottomWrapper .content h6 {
        font-size: 26px;
        text-align: left !important;
    }

    .section2Bottom .section2BottomWrapper .content p {
        font-size: 16px;
        text-align: left !important;
    }

    .section2Bottom .section2BottomWrapper .animated .btns,
    .section2Bottom .section2BottomWrapper .animated {
        display: none;
    }

    .section2Bottom .animated .animationContainer {
        width: 550px;
        margin-bottom: 10px;
    }

    .section2Bottom .btns {
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 5px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        color: #5F5BCD;

    }

    .section3 .top {
        padding: 0 10px;
    }

    .section3 .top .left h6 {
        font-size: 25px;
    }

    .section3 .top .left .point {
        gap: 15px;
    }

    .section3 .top .left .point p {
        font-size: 16px;
    }

    .section3 .top .right img {
        margin-top: 20px;
    }

    .section3 .top .right img {
        width: 100%;
    }

    .section3 .bottom h6 {
        font-size: 25px;
    }

    .section3 .features .feature .content h6 {
        font-size: 25px;
    }

    .section3 .features .feature .content p {
        font-size: 16px;
    }

    .section3 .features .feature .img {
        margin-top: 10px;
    }

    .section4 {
        padding: 50px 0;
    }

    .section4 .top .h4 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .section4 .top .p {
        font-size: 16px;
    }

    .section4 .mid {
        padding-top: 100px;
    }

    .section4 .mid h4 {
        font-size: 30px;
    }

    .section4 .mid .wrapper {
        margin-bottom: 25px;
        display: block;
        height: 100%;
    }

    .section4 .mid .wrapper .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 30px;
        place-items: center;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
    }


    .section4 .mid .wrapper .h5 {
        font-size: 25px;
    }

    .section4 .mid .wrapper .grid .card {
        min-height: 100%;
        padding: 20px;
        max-width: 100%;
    }

    .section4 .mid .wrapper .grid .card h6 {
        font-size: 18px;
    }

    .section4 .bottom .imgBox .content {
        top: 10%;
        left: 5%;
    }

    .section4 .bottom .imgBox img {
        filter: brightness(0.6);
    }

    .section4 .bottom .imgBox .content h6 {
        font-size: 18px;
    }

    .section4 .bottom .imgBox .content p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .section4 .bottom .imgBox .content button {
        width: 170px;
    }

    .formText h3 {
        margin-bottom: 20px;
    }

    .formContainer {
        padding: 50px 20px;
    }

}